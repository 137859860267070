import React, { useState, Fragment } from "react";

export const SectionTabs = ({ title, dummyTabsValues }) => {
	const [tabActive, setTabActive] = useState("Compromiso")

	const handleTabActive = (item) => {
		setTabActive(item)
	}
	console.log(tabActive)
	return (
		<div className="SectionTabs">
			<div className="SectionTabs__container">
				<h1>{title}</h1>
				<div className="card">
					<div className="tabs">
						{dummyTabsValues?.map((item, index) =>
							<div className={`tabs__tab  ${tabActive === item.key && "active"}`} key={index} onClick={() => handleTabActive(item.key)}>
								<h3>{item.title}</h3>
							</div>
						)}
					</div>
					<div className="card__content">
						{dummyTabsValues?.map((item, index) =>
							<Fragment key={index} >
								{tabActive === item.key &&
									<div className="content animate__fadeIn animate__animated">
										<h1>{item.title}</h1>
										<p>{item.text}</p>
									</div>
								}
							</Fragment>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}