import React from "react";

export const SectionBrands = ({title,dummyBrands}) => {
	return (
		<div className="SectionBrands">
			<div className="SectionBrands__container">
				<h1>{title}</h1>
				<div className="brands">
					{dummyBrands?.map((item,index)=>
						<div className="brand" key={index}>
							<img src={item.img}/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}