import React from "react"
import PropTypes from "prop-types"

import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"
import { SectionBannerWe } from "../../components/PageWe/SectionBannerWe/SectionBannerWe";
import { SectionMision } from "../../components/PageWe/SectionMision/SectionMision";
import { SectionBrands } from "../../components/PageWe/SectionBrands/SectionBrands";
import { SectionTabs } from "../../components/PageWe/SectionTabs/SectionTabs";
import { SectionWeLifters } from "../../components/PageLifters/SectionWeLifters/SectionWeLifters";

const We = () => {

	const dummyTabsBanner = [
		{id:1,key:"Mercado",title:"En el Mercado",text:"Revolucionamos el sector logístico por medio de la tecnología. Conectando la oferta con la demanda, constituimos el ecosistema logístico más grande de Latinoamérica con más de 3.000 Lifters y 300 clientes, a lo que se suman rondas de inversión por más de 21 millones de dólares y un plan de trabajo con el que estamos impactando positivamente a Generadores de Carga, Operadores Logísticos, Transportistas y Colaboradores. "},
		{id:2,key:"Personas",title:"En las Personas",text:"Revolucionamos sector logístico por medio de la tecnología. Conectando la oferta con la demanda, constituimos el ecosistema logístico más grande de Latinoamérica con más de 3.000 Lifters y 300 clientes, a lo que se suman rondas de inversión por más de 21 millones de dólares y un plan de trabajo con el que estamos impactando positivamente a Generadores de Carga, Operadores Logísticos, Transportistas y Colaboradores. "},
	]
	const dummyMisionVision = [
		{id:1,title:"Misión",text:"Automatizar la distribución de bienes y mejorar la vida de todos los Lifters"},
		{id:2,title:"visión",text:"Soñamos con mover Latinoamérica con tecnología yeficiencia. "},
	]
	const dummyCardslogistic = [
		{id:1,title:"Transformación Digital",text:"La innovación tecnológica aplicada al sector logístico ha sido un desafio que nos ha permitido transformar la manera de operar, llevando a nuestros clientes y Lifters a desarrollar su máximo potencial.",img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/transformaci%C3%B3n-digital.png"},
		{id:2,title:"Tecnología y Conectividad",text:"Nuestras herramientas tecnológicas permiten dar conectividad a todo el proceso logístico, conectamos cada herramienta y proceso para tener el control, trazabilidad y medición total de las operaciones. ",img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/tecnolog%C3%ADa-conectividad.png"},
		{id:3,title:"El Poder de la Información",text:"Empoderamos a nuestros clientes con Data de Calidad que les permite tomar decisiones inteligentes y acertadas para el crecimiento del negocio.",img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/poder-Informaci%C3%B3n.png"},
	]
	const dummyBrands = [
		{id:1,img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/TREMEX-img.png"},
		{id:2,img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/cruz-verde.png"},
		{id:3,img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/easy.png"},
		{id:4,img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/glr.png"},
		{id:5,img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/inter.png"},
		{id:6,img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/jumbo.png"},
		{id:7,img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/nestle.png"},
		{id:8,img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/tugo.png"},
		{id:9,img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/walmart.png"},
		{id:10,img:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/we/transaga-img.png"},
	]
	const dummyTabsValues = [
		{id:1,title:"Compromiso ",key:"Compromiso",text:"Mantenemos un ritmo de productividad sostenible y una vida plena fuera del trabajo. Maximizamos nuestra capacidad para asumir proyectos creando un equipo diverso que puede unir su experiencia y perspectivas para encontrar soluciones, mejorando la calidad de vida y el bienestar humano. "},
		{id:2,title:"Confianza",key:"Confianza",text:"Mantenemos un ritmo de productividad sostenible y una vida plena fuera del trabajo. Maximizamos nuestra capacidad para asumir proyectos creando un equipo diverso que puede unir su experiencia y perspectivas para encontrar soluciones, mejorando la calidad de vida y el bienestar humano. "},
		{id:3,title:"Calidad",key:"Calidad",text:"Creamos servicios y productos funcionales, comprensibles, agradables y fáciles de usar. Mejoramos la calidad del proceso y el entorno del cliente. Más allá de eso, diseñar y construir servicios de calidad significa mejorar la seguridad, privacidad y accesibilidad del producto; lo que requiere mejoras y beneficios para los usuarios."},
		{id:4,title:"Autogestión",key:"Autogestión",text:"Nos autogestionamos y somos productivos. Nuestro primer instinto es tomar la iniciativa; tomamos decisiones propias e impulsamos el cambio, actuamos de forma independiente, sabemos cuándo colaborar y cuándo pedir ayuda."},
		{id:5,title:"Mejora Continua",key:"Mejora-Continua",text:"Sabemos que siempre podemos ser mejores. Seguimos adelante y confiamos en las ideas en que creemos, sin dejar de estar abiertos y ansiosos por tener mejores perspectivas. Aprendemos cosas nuevas y compartimos esos aprendizajes con nuestros compañeros y la comunidad. Preferimos la viabilidad a largo plazo sobre los atajos de menor tiempo. "},
	]

	return (
		<Layout>
			<div className="page-we">
				<SectionBannerWe
					title={"Nuestra revolución"}
					dummyTabsBanner={dummyTabsBanner}
				/>
				<SectionMision
					dummyMisionVision={dummyMisionVision}
					title={"Proyecciones Liftit"}
					dummyCardslogistic={dummyCardslogistic}
				/>
				<SectionBrands
					title={"Confian sus entregas a Liftit"}
					dummyBrands={dummyBrands}
				/>
				<div>
					<SectionTabs
						title={"Valores"}
						dummyTabsValues={dummyTabsValues}
					/>
					{/* <SectionWeLifters
						
					/> */}
				</div>
			</div>
		</Layout>
	)
}

export default We
