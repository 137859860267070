import Item from "antd/lib/list/Item";
import React, { useState } from "react";

export const SectionBannerWe = ({ title, dummyTabsBanner }) => {
	const [ tabActive, setTabActive ] = useState("Mercado");

	const handleTabActive = (item) => {
		setTabActive(item)
	}

	return (
		<div className="SectionBannerWe">
			<div className="back"/>
			<div className="SectionBannerWe__container">
				<h1>{title}</h1>
				<div className="tabs">
					{dummyTabsBanner?.map((item,index)=>
						<div key={index} className={`tabs__title  ${tabActive === item.key && "active"}`} onClick={()=>handleTabActive(item.key)}>
							<h4>{item.title}</h4>
						</div>
					)}
				</div>
				{dummyTabsBanner?.map((item,index)=>
					<div key={index} className="text animate__fadeIn animate__animated" onClick={()=>handleTabActive(item.key)}>
						{tabActive === item.key &&
							<p>
								{item.text}
							</p>
						}
					</div>
				)}
			</div>
		</div>
	);
}