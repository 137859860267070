import React from "react";

export const SectionMision = ({ dummyMisionVision, title, dummyCardslogistic }) => {
	return (
		<div className="SectionMision">
			<div className="SectionMision__container">
				<h1>{title}</h1>
				<div className="content">
					<div className="SectionMision__container--box-left">
						{dummyMisionVision?.map((item,index)=>
							<div className="card" key={index}>
								<h1>{item.title}</h1>
								<p>{item.text}</p>
							</div>
						)}
					</div>
					<div className="SectionMision__container--box-right">

					</div>
				</div>
				<h3>
					Innovación y calidad en servicios logísticos
				</h3>
				<div className="content-cards">
					{dummyCardslogistic?.map((item,index)=>
						<div className="card-tech" key={index}>
							<div className="img">
								<img src={item.img} alt={item.title} className="img__card"/>
								<div className="back"/>
							</div>
							<h4>{item.title}</h4>
							<p>{item.text}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}